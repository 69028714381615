<div *ngIf="history && history.length">
  <bit-item *ngFor="let h of history">
    <div class="tw-pl-3 tw-py-2">
      <bit-color-password
        class="tw-text-base"
        [password]="h.password"
        [showCount]="false"
      ></bit-color-password>
      <div class="tw-text-sm tw-text-muted">{{ h.lastUsedDate | date: "medium" }}</div>
    </div>
    <ng-container slot="end">
      <bit-item-action>
        <button
          type="button"
          bitIconButton="bwi-clone"
          [appA11yTitle]="'copyPassword' | i18n"
          appStopClick
          (click)="copy(h.password)"
        >
          <i class="bwi bwi-lg bwi-clone" aria-hidden="true"></i>
        </button>
      </bit-item-action>
    </ng-container>
  </bit-item>
</div>
<div class="no-items" *ngIf="!history?.length">
  <p>{{ "noPasswordsInList" | i18n }}</p>
</div>
